/* global $ */

import "whatwg-fetch";
const { DateTime } = require("luxon");

// isInteger polyfill
Number.isInteger =
  Number.isInteger ||
  function (value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const boardChars =
  " ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzç1234567890$-|,()";

const randomString = (len, charSet = boardChars) => {
  let randomString = "";
  for (let i = 0; i < len; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

const buildTableFromData = (data) => {
  const rows = data.split("|");
  let tableHtml = "";

  tableHtml += '<table class="planes">';
  tableHtml += '<tr class="titles">';

  const columnTitles = rows[0].split(",").splice(0, 4);

  tableHtml += columnTitles
    .map((title) => {
      return `<th>${title}</th>`;
    })
    .join("");

  rows.shift();

  tableHtml += "</tr>";

  tableHtml += rows
    .map((row) => {
      const cols = row.split(",").splice(0, 4);
      const note = row.split(",")[5];
      const secondLine = row.split(",")[6];

      return `<tr class="content">${cols
        .map((title, i) => {
          let formattedTitle =
            document.body.clientWidth >= 1024
              ? title.replace(/\$/g, "&nbsp;")
              : title.replace(/\$/g, "");

          if (i === 1 && secondLine) {
            formattedTitle += `<br><div class="vspace"><span class="secondLine">${secondLine}</span></div>`;
          }

          if (i === 1 && note) {
            formattedTitle += `<div class="vspace"><span class="note">${note}</span></div>`;
          }

          let content = `<td>${formattedTitle}</td>`;

          return content;
        })
        .join("")}</tr>`;
    })
    .join("");

  tableHtml += "</table>";

  return tableHtml;
};

let letterState = [];
let letterStateStarted = [];

const letterAnimationTo = (data, speed = 50) => {
  letterState = [];
  letterStateStarted = [];

  const rawItems = [...data].filter((d, i) => {
    return d.acf.type === currentActiveCategory;
  });

  data.unshift(`Vendor,Model,Date,Status`);

  // Set the initial state, change linebreaks so we can recognize them easier
  let alteredData = data
    // .split("\n")
    // .map((p) => p.trim())
    .map((d, i) =>
      i === 0
        ? d
        : `${d.acf.vendor},${d.title.rendered},${d.acf.date},${d.acf.status},${
            d.acf.type
          },${d.acf.note},${d.acf.second_line ? d.acf.second_line : ""}`
    )
    .filter((d, i) => {
      const arr = d.split(",");
      const type = arr[arr.length - 3];

      return i === 0 || type === currentActiveCategory;
    });

  let finishedContent = buildTableFromData(alteredData.join("|"));

  // Don't animate titles
  let titles = alteredData.shift();

  // Join back with | as it's easier to deal with
  alteredData = alteredData.join("|");

  letterState = alteredData.split("").map((c) => {
    if ([",", "|", "$"].indexOf(c) === -1) {
      return 0; // space
    }

    return boardChars.indexOf(c);
  });

  const animate = () => {
    let finished = true;

    letterState.forEach((l, i) => {
      if ([",", "|"].indexOf(boardChars[l]) === -1) {
        if (boardChars[l] !== alteredData[i] && letterStateStarted[i]) {
          finished = false;
          letterState[i]++;
          if (letterState[i] === boardChars.length - 2) letterState[i] = 0;
        }
      }
    });

    let data = letterState.map((i) => {
      // if (!letterStateStarted[i]) return "&nbsp;";

      // if (boardChars[i] === "$") return "<br />";

      return boardChars[i];
    });

    // console.log(data)

    const tableHtml = buildTableFromData(titles + "|" + data.join(""));
    $(".ghost").html(tableHtml);

    if (!finished) {
      setTimeout(animate, speed);
    } else {
      // console.log("animation finished");
    }
  };

  let starterIndex = 0;
  const checkLettersReadyToBeAnimated = () => {
    for (let i = 0; i < 20; i++) {
      letterStateStarted[starterIndex + i] = true;
    }
    starterIndex += 20;

    if (starterIndex < alteredData.length + 5) {
      setTimeout(checkLettersReadyToBeAnimated, 50);
    }
  };

  $("#u379").html(`
    <div class="ghost"></div>
    <div class="real">
      ${finishedContent}
    </div>
  `);

  rawItems.forEach((item, i) => {
    let pictures = item.acf.picture || [];
    let numPhotos = pictures.length;

    // const photos = pictures.map((p) => ({ src: p.url }));
    const photos = pictures.map((p) => ({ src: p }));

    if (numPhotos) {
      $(`.real tr:nth-child(${i + 2})`).magnificPopup({
        items: photos,
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function () {
            return "";
          },
        },
      });
    }
  });

  checkLettersReadyToBeAnimated();
  animate();
};

let planesData = null;

let loadingCount = 0;

let flyByPlaneAnimInterval = null;

const getLoadingShape = ({ category }) => {
  if (category === "aircraft") {
    return `
    8d,
 d, "88d,
 88d,8888888
  K8888888888888=
 88Y"8888888
 Y" ,88Y"
    8Y"
    `;
  }
  if (category === "av") {
    return `
    .--._____,
 .-='=='==-, "
(O_o_o_o_o_O)
`;
  }
  if (category === "boat") {
    return `
           ___\\__
          |______\\______
    ______/_____/_______\\_______
    |              > > >       /
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    `;
  }
};

const startAnimation = ({ category }) => {
  if (flyByPlaneAnimInterval) {
    clearInterval(flyByPlaneAnimInterval);
  }

  let loadingAnimationSpeed = 15;

  if (category === "av") loadingAnimationSpeed = 20;
  if (category === "boat") loadingAnimationSpeed = 20;

  flyByPlaneAnimInterval = setInterval(() => {
    let loadingDots = "";
    let loadingSpaces = "";
    for (let i = 0; i < loadingCount; i++) {
      loadingDots += "+";
      loadingSpaces += " ";
    }
    let loadingShape = getLoadingShape({ category });

    loadingShape = loadingShape.split("\n");
    loadingShape = loadingShape.map((row, rowIndex) => {
      if (category === "aircraft") {
        if (rowIndex === 0 || rowIndex === loadingShape.length - 2) {
          return [...Array(loadingCount).keys()].map((i) => "~").join("") + row;
        }
      }

      if (category === "av") {
        if (rowIndex === 3) {
          return [...Array(loadingCount).keys()].map((i) => ".").join("") + row;
        }
      }

      if (category === "boat") {
        if (rowIndex === loadingShape.length - 2) {
          return [...Array(loadingCount).keys()].map((i) => "~").join("") + row;
        }
      }

      return loadingSpaces + row;
    });

    loadingShape = loadingShape.join(`\n`);

    $(".loading").html(loadingShape);
    if (loadingCount > 100) {
      loadingCount = 0;
      if (planesData) {
        letterAnimationTo(planesData);
        clearInterval(flyByPlaneAnimInterval);
      }
    }
    loadingCount++;
    // if (loadingCount > 3) loadingCount = 0
  }, loadingAnimationSpeed);
};

let currentActiveCategory = getParameterByName("category") || "aircraft";

document.addEventListener("DOMContentLoaded", () => {
  $("#u379 div > p").wrap("<div></div>");

  fetch("https://wp2.lucjanssen32nd.com/wp-json/wp/v2/model?per_page=100")
    .then((r) => r.json())
    .then((data) => {
      const planesWithoutDate = data.filter((d) => !d.acf.date);
      const planesWithDate = data.filter((d) => d.acf.date);

      planesData = planesWithDate.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.

        return (
          DateTime.fromFormat(b.acf.date, "dd-MM-yyyy").toJSDate() -
          DateTime.fromFormat(a.acf.date, "dd-MM-yyyy").toJSDate()
        );
      });

      planesData.push(...planesWithoutDate);
    });

  startAnimation({ category: currentActiveCategory });
});

["aircraft", "av", "boat"].forEach((category) => {
  document
    .querySelector(`#select-${category}`)
    .addEventListener("mouseup", (e) => {
      window.location.href = `?category=${category}`;

      // if (category === currentActiveCategory) {

      // }
      // else {
      //   // try {}
      //     document.querySelector(`#select-${currentActiveCategory}`).classList.remove('select-category-active');
      //     document.querySelector(`#select-${category}`).classList.add('select-category-active');
      // }
      // currentActiveCategory = category;

      // startAnimation();
    });

  if (currentActiveCategory === category) {
    document
      .querySelector(`#select-${category}`)
      .classList.add("select-category-active");
  }
});
